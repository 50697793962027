import React, { useState } from "react";
import styles from "./Modal.module.scss";

import { images, icons } from "../../../assets";

import { Button } from "../../../components";
import CategoriesIcon from "../../../constants/categoriesIcon";

const { places } = icons;

const Modal = ({ onClose, report, onAdjust }) => {
  console.log("RESPORT", report);

  const [inputVal, setInputVal] = useState("");

  const Icon = CategoriesIcon[report.place.category];

  return (
    <div className={styles.wrapper} onClick={onClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.container_close} onClick={onClose}>
          <icons.CloseIcon />
        </div>

        <div className={styles.container_info}>
          <img
            className={styles.container_info_avatar}
            src={report.place.photo}
          />
          <div className={styles.container_info_name}>{report.place.title}</div>
          <div className={styles.container_info_location}>
            {report.place.address}
          </div>

          <div className={styles.container_info_footer}>
            <div className={styles.container_info_footer_avatar}>
              {/* <places.RestourantIcon /> */}
              <Icon />
            </div>
            <div className={styles.container_info_footer_name}>
              {report.place.category}
            </div>
          </div>
        </div>

        <div className={styles.container_report}>
          <div className={styles.container_report_info}>
            <div className={styles.container_report_info_name}>
              {report.name}
            </div>
            <div className={styles.container_report_info_description}>
              {report.description}
            </div>
          </div>

          {/* <div className={styles.container_report_count}>
            <div className={styles.container_report_count_number}>
              Reported number:
            </div>
            <div className={styles.container_report_count_amount}>
              {report.crowd}
            </div>
            <div className={styles.container_report_count_button}>
              <Button title="Set this as Max" small onClick={onAdjust} />
            </div>
          </div> */}
        </div>

        <div className={styles.container_custom}>
          <div className={styles.container_custom_title}>Custom Number</div>
          <div className={styles.container_custom_footer}>
            <div className={styles.container_custom_footer_input}>
              <input
                value={inputVal}
                onChange={(e) => {
                  if (
                    /^[1-9][0-9]?$|^100$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputVal(e.target.value);
                  }
                }}
                type="text"
                className={styles.container_custom_footer_input_input}
                placeholder="0"
              />
            </div>
            <div className={styles.container_custom_footer_button}>
              <Button
                title="Adjust Max"
                onClick={() => onAdjust(!!inputVal ? +inputVal : 0)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
