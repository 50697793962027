import { icons } from "../assets";

const { places } = icons;

const CategoriesIcon = {
  Sights: places.SightsIcon,
  Food: places.FoodIcon,
  Hotels: places.HotelsIcon,
  Outdoors: places.OutdoorsIcon,
  Activities: places.ActivitiesIcon,
  Museums: places.MuseumsIcon,
  Coffee: places.CoffeeIcon,
  Shopping: places.ShoppingIcon,
  Drinks: places.DrinksIcon,
  Dessert: places.DessertIcon,
  Exercise: places.ExerciseIcon,
  "Self-Care": places.SelfCareIcon,
  Other: places.OtherIcon,
};

export default CategoriesIcon;
