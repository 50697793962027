import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getJwtToken } from "../../constants/jwtToken";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  return <div></div>;
};

export default NotFound;
