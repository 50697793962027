import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, NavLink } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import Active from "../Active";

import { images, icons } from "../../assets";

import { removeJwtToken } from "../../constants/jwtToken";

import Modal from "./Modal";

const Dashboard = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      navigate("/dashboard");
    }
  }, []);

  const logout = () => {
    removeJwtToken();
    navigate("/login");
  };

  return (
    <div className={styles.page}>
      <div className={styles.page_left}>
        <div>
          <div className={styles.page_left_logo}>
            <img src={images.logo} className={styles.page_left_logo_item} />
          </div>
          <div className={styles.page_left_link}>
            <div className={styles.page_left_link_icon}>
              <icons.ClipboardIcon />
            </div>
            <p>User Reports</p>
          </div>
        </div>

        <div className={styles.page_left_bottom}>
          <div className={styles.page_left_bottom_icon}>
            <img src={images.avatar} />
          </div>
          <div>
            <p className={styles.page_left_bottom_name}>Admin</p>
            <p className={styles.page_left_bottom_account} onClick={logout}>
              Log out
            </p>
          </div>
        </div>
      </div>

      <div className={styles.page_right}>
        <h1 className={styles.page_right_title}>Reports</h1>

        <Active />
      </div>

      {isOpenModal && <Modal onClose={() => setIsOpenModal(false)} />}
    </div>
  );
};

export default Dashboard;
