import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";

import { ReportsAPI } from "../../api";

import { Input, Button } from "../../components";

import { setJwtToken, getJwtToken } from "../../constants/jwtToken";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (getJwtToken()) {
      navigate("/dashboard");
    }
  }, []);

  const handleLogin = async () => {
    try {
      const { token } = await ReportsAPI.login(email, password);
      setJwtToken(token);
      navigate("/dashboard");
    } catch (e) {
      setError("Incorrect login or email");
      console.log(e);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.page_login}>
        <div className={styles.page_login_item}>
          <Input
            value={email}
            onChange={(val) => {
              setError("");
              setEmail(val);
            }}
            placeholder="login"
            label="Login"
            type="email"
          />
        </div>
        <div className={styles.page_login_item}>
          <Input
            value={password}
            onChange={(val) => {
              setError("");
              setPassword(val);
            }}
            placeholder="password"
            label="Password"
            type="password"
          />
        </div>
        {error && <div className={styles.page_login_error}>{error}</div>}
        <Button
          title="Log in"
          onClick={handleLogin}
          disable={email.length === "" || password.length === ""}
        />
      </div>
    </div>
  );
};

export default Login;
