import React from "react";
import { Navigate } from "react-router-dom";

import { getJwtToken } from "../../constants/jwtToken";

const ProtectedRoute = ({ children }) => {
  if (!getJwtToken()) return <Navigate to="/login" replace />;

  return children;
};

export default ProtectedRoute;
