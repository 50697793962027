import React from "react";
import styles from "./Input.module.scss";

const Input = ({ value, onChange, label, error, ...rest }) => {
  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.wrapper_label}>{label}</div>}
      <input
        className={styles.wrapper_input}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {error && <div className={styles.wrapper_error}>{error}</div>}
    </div>
  );
};

export default Input;
