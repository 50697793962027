import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./Active.module.scss";

import { ReportsAPI } from "../../api";

import {
  ReportTable,
  Button,
  ArchivedModal,
  AdjustModal,
} from "../../components";

const Active = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMaxReports, setIsMaxReports] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedReport, setSelectedReport] = useState(null); // for delete
  const [selectedReportForAdjust, setSelectedReportForAdjust] = useState(null); // for delete

  const [customPercent, setCustomPercent] = useState(null);

  useEffect(() => {
    fetchReports();
  }, [page]);

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const { count, rows } = await ReportsAPI.getReports(page, 10);

      const updatedReports = [...reports, ...rows];

      if (updatedReports.length >= count) {
        setIsMaxReports(true);
      }

      setReports(updatedReports);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const archiveReport = async () => {
    try {
      const resp = await ReportsAPI.archiveReport(selectedReport.id);
      setReports(reports.filter((r) => r.id !== selectedReport.id));
      setSelectedReport(null);
    } catch (e) {
      console.log(e);
    }
  };

  const adjustReport = async () => {
    try {
      const resp = await ReportsAPI.adjustReport(
        selectedReportForAdjust.id,
        customPercent
      );
      const index = reports.findIndex(
        (report) => report.id === selectedReportForAdjust.id
      );
      setReports((prev) => {
        let state = [...prev];
        state[index].status = "adjusted";
        state[index].crowd = customPercent;

        return state;
      });
      setSelectedReportForAdjust(null);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAdjust = (report, customPercent) => {
    if (typeof customPercent === "number") setCustomPercent(customPercent);
    setSelectedReportForAdjust(report);
  };

  const handleArchived = (report) => {
    setSelectedReport(report);
  };

  return (
    <div>
      <ReportTable
        data={reports}
        onAdjust={handleAdjust}
        onArchived={handleArchived}
      />
      {isLoading && (
        <div className={styles.button_wrapper}>
          <ClipLoader />
        </div>
      )}

      {!isLoading && !isMaxReports && (
        <div className={styles.button_wrapper}>
          <div className={styles.button}>
            <Button title="Show more" onClick={() => setPage(page + 1)} />
          </div>
        </div>
      )}

      {!isLoading && reports.length === 0 && (
        <div className={styles.noReports}>No reports yet</div>
      )}

      {selectedReport && (
        <ArchivedModal
          onClose={() => setSelectedReport(null)}
          onArchive={archiveReport}
        />
      )}

      {selectedReportForAdjust && (
        <AdjustModal
          onClose={() => {
            if (customPercent !== null) {
              setCustomPercent(null);
            }
            setSelectedReportForAdjust(null);
          }}
          onAdjust={adjustReport}
        />
      )}
    </div>
  );
};

export default Active;
