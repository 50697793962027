import apiClient from "./apiClient";

class Reports {
  static login(email, password) {
    return apiClient
      .post("admins/login", { email, password })
      .then(({ data }) => data);
  }

  static getReports(page, limit = 10) {
    return apiClient
      .get(`reports?page=${page}&limit=${limit}`)
      .then(({ data }) => data);
  }

  static archiveReport(id) {
    return apiClient.put(`reports/${id}/archive`).then(({ data }) => data);
  }

  static adjustReport(id, customPercent) {
    console.log("LAST adjustReport", customPercent);
    const data = {};
    if (typeof customPercent === "number") {
      console.log("33333333");
      data.custom = customPercent;
    }

    console.log("DATA", data);
    return apiClient.put(`reports/${id}/adjust`, data).then(({ data }) => data);
  }
}

export default Reports;
