import React, { useState } from "react";
import styles from "./ReportTable.module.scss";

import { images, icons } from "../../assets";

import { Button } from "../index";
import Modal from "../../pages/Dashboard/Modal";
import CategoriesIcon from "../../constants/categoriesIcon";

const { places } = icons;

const ReportTable = ({ data, onArchived, onAdjust }) => {
  const [choosedReport, setChoosedReport] = useState(null);

  const renderReports = () => {
    return data.map((report, index) => {
      const Icon = CategoriesIcon[report.place.category];

      console.log(Icon);
      return (
        <div key={index} className={styles.page_right_table_item}>
          <div className={styles.page_right_table_item_info}>
            <img
              className={styles.page_right_table_item_info_avatar}
              src={report.place.photo}
            />
            <div>
              <div className={styles.page_right_table_item_info_head}>
                <div className={styles.page_right_table_item_info_head_avatar}>
                  {<Icon />}
                </div>
                <div className={styles.page_right_table_item_info_head_title}>
                  {report.place.category}
                </div>
              </div>
              <div className={styles.page_right_table_item_info_name}>
                {report.place.title}
              </div>
              <div className={styles.page_right_table_item_info_location}>
                {/* {report.location} */}
              </div>
            </div>
          </div>

          <div className={styles.page_right_table_item_report}>
            <div className={styles.page_right_table_item_report_name}>
              {report.name}
            </div>
            <div className={styles.page_right_table_item_report_description}>
              {report.description}
            </div>
          </div>

          <div className={styles.page_right_table_item_actions}>
            <div className={styles.page_right_table_item_actions_count}>
              {report.status !== "report" ? `${report.crowd}%` : " "}
            </div>

            {report.status !== "update" && (
              <div className={styles.page_right_table_item_actions_button}>
                <Button
                  title="Adjust Max"
                  disable={report.status === "adjusted"}
                  onClick={(event) => {
                    if (report.status === "adjusted") return;
                    setChoosedReport(report);
                    event.stopPropagation();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.page_right_table}>
      <div className={styles.page_right_table_head}>
        <div className={styles.page_right_table_head_info}>Name & Category</div>
        <div className={styles.page_right_table_head_report}>User report</div>
        <div className={styles.page_right_table_head_actions}>
          Reported number
        </div>
      </div>

      {!!choosedReport && (
        <Modal
          onClose={() => setChoosedReport(null)}
          report={choosedReport}
          onAdjust={(inputVal) => {
            if (typeof inputVal === "number") {
              onAdjust(choosedReport, inputVal);
            } else {
              onAdjust(choosedReport);
            }
            setChoosedReport(null);
          }}
        />
      )}

      {renderReports()}
    </div>
  );
};

export default ReportTable;
