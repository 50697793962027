import axios from "axios";

import { getJwtToken } from "../constants/jwtToken";

const API_ENDPOINT = "https://api.rabbledigital.com/";

const apiClient = axios.create({
  baseURL: API_ENDPOINT,
});

apiClient.interceptors.request.use(async (config) => {
  const token = getJwtToken();

  config.headers["ApiKey"] = `4ym5G8qDieuCezXrXf9uQhOddTv011W/NQ7D5Q8QB5Q`;
  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

export default apiClient;
