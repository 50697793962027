import React from "react";
import styles from "./AdjustModal.module.scss";

import { images, icons } from "../../assets";

import { Button } from "../index";

const { places } = icons;

const AdjustModal = ({ onClose, onAdjust }) => {
  return (
    <div className={styles.wrapper} onClick={onClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.container_close} onClick={onClose}>
          <icons.CloseIcon />
        </div>

        <h3 className={styles.title}>Are you sure?</h3>

        <div className={styles.actions}>
          <div className={styles.actions_item}>
            <Button title="Close" onClick={onClose} />
          </div>
          <div className={styles.actions_item}>
            <Button title="Adjust" onClick={onAdjust} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjustModal;
