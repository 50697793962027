import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";

import {
  Dashboard,
  Login,
  NotFound,
  Active,
  Adjusted,
  Archived,
  AdjustedAdmin,
  AdjustedUsers,
} from "./pages";
import { ProtectedRoute } from "./components";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        {/* <Route path="active" element={<Active />} />
        <Route path="adjusted" element={<Adjusted />}>
          <Route path="admin" element={<AdjustedAdmin />} />
          <Route path="users" element={<AdjustedUsers />} />
        </Route> */}
        {/* <Route path="archived" element={<Archived />} /> */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
