import { ReactComponent as SightsIcon } from "./sights.svg";
import { ReactComponent as FoodIcon } from "./food.svg";
import { ReactComponent as HotelsIcon } from "./hotels.svg";
import { ReactComponent as OutdoorsIcon } from "./outdoors.svg";
import { ReactComponent as ActivitiesIcon } from "./activities.svg";
import { ReactComponent as MuseumsIcon } from "./museums.svg";
import { ReactComponent as ShoppingIcon } from "./shopping.svg";
import { ReactComponent as DrinksIcon } from "./drinks.svg";
import { ReactComponent as DessertIcon } from "./dessert.svg";
import { ReactComponent as ExerciseIcon } from "./exercise.svg";
import { ReactComponent as SelfCareIcon } from "./selfCare.svg";
import { ReactComponent as CoffeeIcon } from "./coffee.svg";

import { ReactComponent as OtherIcon } from "./other.svg";

export default {
  SightsIcon,
  FoodIcon,
  HotelsIcon,
  OutdoorsIcon,
  ActivitiesIcon,
  MuseumsIcon,
  ShoppingIcon,
  DrinksIcon,
  DessertIcon,
  ExerciseIcon,
  SelfCareIcon,
  OtherIcon,
  CoffeeIcon,
};
