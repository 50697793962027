import React from "react";
import styles from "./Button.module.scss";

const Button = ({ title, onClick, disable = false, small = false }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disable}
      className={`${styles.button} ${small ? styles.button_small : ""} ${
        disable ? styles.button_disable : ""
      }`}
    >
      {title}
    </button>
  );
};

export default Button;
